<template>
    <div class="smsCenter_wpr" v-if="modelValue">
        <div class="contact_area">
            <button type="button" class="toggle_bar" @click="closeFunc()"><i class="fas fa-chevron-right"></i></button>
            <div class="sms_action" :class="{'show' : isFilter}">
                <button type="button" class="toggle_bar" @click="filterFunc()"><i class="fas fa-chevron-right"></i></button>
                <ul>
                    <li @click="changeSorting('all')" :class="{ active : params.sorting == 'all' }">
                        <img src="@/assets/images/inbox2.svg" alt="">Inbox
                    </li>
                    <li @click="changeSorting('unread')" :class="{ active : params.sorting == 'unread' }">
                        <img src="@/assets/images/unread.svg" alt="">Unread <span v-if="stats.unreadCount">{{ stats.unreadCount }}</span>
                    </li>
                    <li @click="changeSorting('flagged')" :class="{ active : params.sorting == 'flagged' }">
                        <img src="@/assets/images/star2.svg" alt="">Starred <span  v-if="stats.flaggedCount">{{ stats.flaggedCount }}</span>
                    </li>
                    <li @click="changeSorting('sent')" :class="{ active : params.sorting == 'sent' }">
                        <img src="@/assets/images/sent.svg" alt="">Sent
                    </li>
                </ul>
                <ul class="other_actions">
                    <li @click="allScheduledMessage = true">
                        <img src="@/assets/images/calendar2.svg" alt="">Scheduled
                    </li>
                    <li @click="changeSorting('archived')" :class="{ active : params.sorting == 'archived' }">
                        <img src="@/assets/images/archive2.svg" alt="">Archived
                    </li>
                </ul>
            </div>
            <div class="user_list">
                <ul class="message_list infinite-wrapper" v-if="contacts.length">
                    <li v-for="(contact, c) of contacts" :key="c" :class="{ active: contact.id == selectedContact.id }">
                        <div class="user_box" @click="handleSelectedContact(contact)">
                            <label for="robert" class="flag">
                                <svg class="tag" :class="{ active: contact.flagged }" @click.stop.prevent="toggleFlagged($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                            </label>
                            <div class="img_wpr pointer">
                                <img :src="contact.photo">
                                <span class="active"></span>
                            </div>
                            <div class="user_info">
                                <h4 class="pointer">{{ contact.name }} <span>{{ contact.chat_created_at ? moment.utc(contact.chat_created_at).local().fromNow() : '' }}</span></h4> 
                                <div class="msg_cont">
                                    <p class="pointer" v-if="contact.message">{{ contact.message}}</p> 
                                    <p v-else-if="contact.media"><i class="fas fa-paperclip"></i></p>
                                    <p v-else>No message yet</p>
                                    <span class="read pointer" @click.stop="actionMenu($event)" v-click-outside="closeActionMenu">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <div class="dropdown_wpr">
                                            <ul>
                                                <li @click="handleProfile(contact)">Open Profile</li>
                                                <li v-if="params.sorting !== 'archived'" @click="toggleMarkReadUnread($event, contact, 0)">Mark as read</li>
                                                <li v-if="params.sorting !== 'archived'" @click="toggleMarkReadUnread($event, contact, 1)">Mark as unread</li>
                                                <li @click="toggleAddNote(contact)">Add a note</li>
                                                <li @click="toggleTag(contact)">Add a tag</li>
                                                <li @click="toggleUpdateSegment(contact)">Update segment</li>
                                                <li @click="toggleArchivePortalChat(0, contact)" v-if="params.sorting == 'archived'">Unarchive</li>
                                                <li @click="toggleArchivePortalChat(1, contact)" v-else>Archive chat</li>
                                            </ul>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <infinite-loading  class="infinite-loading" :class="{ 'no-contacts': contacts.length == 0 }" @infinite="handleContacts" target=".infinite-wrapper" :distance="4" :identifier="resetInfiniteWatcher" />
                </ul>
                <div class="empty_text" v-if="contacts.length == 0 && !loader">
                    <img src="@/assets/images/empty_chat.svg">
                    <h4>No contact found.</h4>
                </div>
                <infinite-loading v-if="loader && !contacts.length"/>
            </div>
        </div>
        <div class="msg_board" :class="{'show' : showConversation}">
            <div class="header-search-main">
                <div class="form-field show">
                    <input type="text" name="search" @input="isTyping = true" v-model.trim="params.search" placeholder="Search By Phone, Name or Tag">
                </div>
                <button class="header-search-btn">
                    <i class="fas fa-search"></i>
                </button>
            </div>
            <div class="chat_board">
                <div class="user_header" v-if="selectedContact.id">
                    <a @click="showConversation = false;" class="back"><i class="fas fa-long-arrow-alt-left"></i></a>
                    <h3 @click="profile = true">
                        <img :src="selectedContact.photo" alt="" class="mr-3 user_img">{{ selectedContact.name }}
                    </h3>
                    <ul class="chatbox-header">
                        <li class="read pointer" @click.stop="actionMenu($event)" v-click-outside="closeActionMenu">
                            <i class="fas fa-ellipsis-v"></i>
                            <div class="dropdown_wpr">
                                <ul>
                                    <li @click="handleProfile(selectedContact)">Open Profile</li>
                                    <li v-if="params.sorting !== 'archived'" @click="toggleMarkReadUnread($event, selectedContact, 0)">Mark as read</li>
                                    <li v-if="params.sorting !== 'archived'" @click="toggleMarkReadUnread($event, selectedContact, 1)">Mark as unread</li>
                                    <li @click="toggleAddNote(selectedContact)">Add a note</li>
                                    <li @click="toggleTag(selectedContact)">Add a tag</li>
                                    <li @click="toggleUpdateSegment(selectedContact)">Update segment</li>
                                    <li @click="toggleArchivePortalChat(0, selectedContact)" v-if="params.sorting == 'archived'">Unarchive</li>
                                    <li @click="toggleArchivePortalChat(1, selectedContact)" v-else>Archive chat</li>
                                </ul>
                            </div>
                        </li>
                        <li @click="lauchPad = true" v-tooltip="`Open your launchpad and perform actions`" position="left">
                            <img src="@/assets/images/launchpad.svg" alt="">
                        </li>
                    </ul>
                </div>
                <portal-chat-box v-if="selectedContact" :contact="selectedContact" :update-contact-list="handleContacts" ref="portal-chatbox" :filter="params.sorting" />
                <div class="empty_chat" v-else>
                    <img src="@/assets/images/empty_chat.svg">
                    <h2>No conversations found.</h2>
                </div>
            </div>
        </div>
    </div>
    <profile-component v-model="profile" :contact="selectedContact" />
    <launch-pad v-model="lauchPad" :contact="selectedContact" />
    <scheduled-message v-model="scheduledMessage" :contact-id="selectedContact.id" />
    <all-scheduled-message v-model="allScheduledMessage" />
    <add-note-component v-model="addNote" :contacts="[selectedContact.id]" source="portal-chat" />
    <add-tag-component v-model="addTag" :contacts="[selectedContact.id]" />
    <update-segment v-model="updateSegment" :contacts="[selectedContact.id]" :status="selectedContact.segment" />
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const PortalChatBox = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/PortalChatBox'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/contact/components/Profile'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const ScheduledMessage = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/ScheduledMessage'))
    const AllScheduledMessage = defineAsyncComponent(() => import('@/pages/sms-center/portal-chat/AllScheduledMessage'))
    const AddNoteComponent = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/AddNote'))
    const AddTagComponent = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))
    const UpdateSegment = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/contact/SwitchTab'))


    import InfiniteLoading from 'v3-infinite-loading'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import moment from 'moment'
    import Swal from 'sweetalert2'

    export default {
        name: 'Portal Chat Dashboard',

        data () {
            return {
                allScheduledMessage: false,
                moment,
                params: {
                    per_page: 10,
                    page: 1,
                    last_page: 1,
                    search: '',
                    sorting: 'all',
                },
                state: undefined,
                loader: false,
                contacts: [],
                isTyping: false,
                resetInfiniteWatcher: 0,
                selectedContact: {},
                scheduledMessage: false,
                lauchPad: false,
                profile: false,
                autoSelectContact: false,
                addNote: false,
                showConversation: false,
                addTag: false,
                updateSegment: false,
            };
        },

        props: {
            modelValue: Boolean,
            closeFunc: Function,
            isFilter: Boolean,
            filterFunc: Function,
            portalChatSearch: String,
        },

        emit: ['update:modelValue', 'update:isFilter'],

        components: {
            PortalChatBox,
            InfiniteLoading,
            ProfileComponent,
            LaunchPad,
            ScheduledMessage,
            AllScheduledMessage,
            AddNoteComponent,
            AddTagComponent,
            UpdateSegment,
        },

        watch: {
            modelValue (val) {
                if (val) {
                    const vm = this;

                    vm.handleContacts(undefined, true);
                }
            },

            portalChatSearch (val) {
                const vm = this;

                vm.isTyping = true;
                vm.params.search = val;
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.params.page      = 1;
                            vm.params.last_page = 1;
                            vm.contacts         = [];
                            vm.loader           = false;
                            vm.resetInfiniteWatcher++;

                            vm.handleContacts(undefined, true);
                        }
                    }
                }
            },

            'params.sorting' () {
                const vm  = this;

                vm.params.page      = 1;
                vm.params.search    = '';
                vm.params.last_page = 1;
                vm.contacts         = [];
                vm.loader           = false;
                vm.selectedContact  = {};
                vm.resetInfiniteWatcher++;

                const chatbox = vm.$refs['portal-chatbox'];

                setTimeout(function () {
                    chatbox.chatLogs = [];
                }, 10);

                vm.handleContacts(undefined, true);
            },

            receiveChatLogWatcher (val) {
                const vm = this;

                vm.handleContacts(undefined, true);
            }
        },

        computed: mapState({
            stats: state => state.portalChatModule.stats,
            user: state => state.authModule.user,
            receiveChatLogWatcher: state => state.portalChatModule.receiveChatLogWatcher,
        }),

        mounted () {
            const vm = this;

            if (vm.$route.query.tab && vm.$route.query.tab == 'portal-chat') {
                vm.autoSelectContact = true
            }

            vm.handleContacts(undefined, true);
        },

        methods: {
            ...mapActions({
                getPortalContacts: 'portalChatModule/getPortalContacts',
                markContactAsFlagged: 'contactModule/markContactAsFlagged',
                archiveUnarchivePortalChat: 'portalChatModule/archiveUnarchivePortalChat',
                getStats: 'portalChatModule/getStats',
                updateMarkAsReadUnread: 'portalChatModule/updateMarkAsReadUnread',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            toggleFlagged (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let flagged = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    flagged = 0;
                } else {
                    element.classList.add('active');
                    flagged = 1;
                }

                vm.markContactAsFlagged({ flagged, contact_id }).then((result) => {
                    if (!result) {
                        if (flagged == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }

                    vm.getStats();
                });
            },

            handleContacts ($state, force = false) {
                const vm = this;

                if ($state) {
                    vm.state = $state;
                } else {
                    vm.contacts = [];
                }

                if (force) {
                    vm.params.per_page  = 10;
                    vm.params.page      = 1;
                    vm.params.last_page = 1;
                }

                if (vm.$route.query.contact) {
                    vm.params.contact = vm.$route.query.contact;
                }

                if ((vm.params.page <= vm.params.last_page ) || force) {
                    if (!vm.loader) {
                        vm.loader = true;
                        vm.getPortalContacts(vm.params).then((result) => {
                            if (result.data && result.data.length) {
                                vm.params.last_page = result.last_page;
                                vm.params.page = result.current_page + 1;
                                vm.contacts.push(...result.data);
                                vm.loader = false;
                                vm.$parent.refreshLoader = false;

                                if (vm.$parent.$parent) {
                                    vm.$parent.$parent.refreshLoader = false;
                                }

                                if (vm.autoSelectContact) {
                                    const contact = vm.contacts.filter(c => c.id == vm.$route.query.contact)[0];

                                    if (contact) {
                                        vm.handleSelectedContact(contact)
                                    }

                                    vm.autoSelectContact = false;
                                }

                                if (vm.state != undefined) {
                                    vm.state.loaded();
                                }

                                if ($state == undefined || (vm.contacts.length == result.data.length)) {
                                    if (!vm.selectedContact.id) {
                                        vm.selectedContact = result.data[0];
                                    }
                                }
                            } else {
                                if (vm.state != undefined) {
                                    vm.state.complete();
                                }

                                vm.loader                   = false;
                                vm.$parent.refreshLoader    = false;
                                vm.selectedContact          = {};

                                if (vm.$parent.$parent) {
                                    vm.$parent.$parent.refreshLoader = false;
                                }
                            }
                        });
                    }
                } else {
                    if (vm.state != undefined) {
                        vm.state.complete();
                    }
                }
            },

            handleSelectedContact (contact) {
                const vm = this;

                vm.selectedContact = contact;
                vm.showConversation = true;
                vm.closeFunc();
            },

            toggleArchivePortalChat (status, contact) {
                const vm      = this;
                const message = `This conversation will be moved ${ status == 0 ? 'back to your inbox.' : 'to your archived folder.' } Ok to proceed?`;
                const options = Helper.swalConfirmOptions('Are you sure?', message);

                options.preConfirm = () => {
                    return vm.archiveUnarchivePortalChat({ status, contact_id: contact.id }).then((result) => {
                        if (result) {
                            const chatbox = vm.$refs['portal-chatbox'];

                            setTimeout(function () {
                                chatbox.chatLogs = [];
                                vm.handleContacts(undefined, true);
                            }, 10);
                        }
                    })
                }

                Swal.fire(options);
            },

            changeSorting(sort) {
                const vm = this;

                if (vm.params.sorting != sort) {
                    vm.contacts = []
                    vm.params.sorting = sort
                    vm.$emit('toggle:filter', false);
                }
            },

            resetChatBox () {
                const vm = this;
                const chatbox = vm.$refs['portal-chatbox'];

                if (chatbox && chatbox.resetForm) {
                    setTimeout(function () {
                        chatbox.resetForm();
                    }, 10);
                }
            },

            toggleMarkReadUnread (e, contact, value) {
                const vm = this;

                const params = {
                    value,
                    contact_id: contact.id,
                }

                vm.updateMarkAsReadUnread(params).then((result) => {
                    if (result) {
                    } else {
                        Toastr.error('Mark as read/unread work only on inbound chat!');
                    }

                    vm.getStats();
                });
            },

            actionMenu (ev) {
                const vm = this;
                let el = ev.currentTarget.closest('.read');
                let tarEl = el.querySelector('.dropdown_wpr');

                if (tarEl.classList.contains('active')) {
                    tarEl.classList.remove('active');
                } else {
                    vm.closeActionMenu();

                    tarEl.classList.add('active');
                }
            },

            closeActionMenu () {
                let allTarEl = document.querySelectorAll('.message_list .user_box .dropdown_wpr');
                let allTarEl2 = document.querySelectorAll('.msg_board .read .dropdown_wpr');

                allTarEl.forEach(function(item){
                    if(item.classList.contains('active')){
                        item.classList.remove('active');
                    }
                })

                allTarEl2.forEach(function(item){
                    if(item.classList.contains('active')){
                        item.classList.remove('active');
                    }
                })
            },

            handleProfile (contact) {
                const vm = this;

                vm.selectedContactV2 = contact;
                vm.profile = true;
            },

            toggleAddNote (contact) {
                const vm = this;

                if (contact && contact.id) {
                    vm.selectedContactV2 = contact;
                    vm.addNote = true;
                } else {
                    console.error('Contact or contact.id is undefined');
                }
            },

            toggleTag (contact) {
                const vm = this;

                vm.selectedContactV2 = contact;
                vm.addTag = true;
            },

            toggleUpdateSegment (contact) {
                const vm = this;

                vm.selectedContactV2 = contact;
                vm.updateSegment = true;
            },

        },
    }
</script>

<style scoped>
    .smsCenter_wpr {
        display: flex;
        height: calc(100vh - 100px);
    }

    .msg_board {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
    .chat_board{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
    }
    .msg_board .header-search-main {
        border: 0;
        height: 44px;
        flex-shrink: 0;
        border-radius: 25px;
        padding: 0 44px 0 0;
        margin-bottom: 10px;
        position: relative;
        background: #fff;
        overflow: hidden;
    }
    .msg_board .header-search-main .form-field{
        margin: 0;
        opacity: 1;
        width: 100%;
    }
    .msg_board .header-search-main .form-field input{
        font-size: 13px;
        line-height: 45px;
        padding: 0 0 0 20px;
        color: #999 !important;
    }
    .msg_board .header-search-main .form-field input:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    }
    .msg_board .header-search-main .header-search-btn {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 0;
        font-size: 13px;
        color: #2f7eed;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .msg_board .user_header {
        padding: 15px 10px 15px 20px;
        background: #fff;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #f3f3f3;
        display: flex;
        align-items: center;
    }
    .msg_board .user_header .back{
        width: 25px;
        height: 25px;
        text-decoration: none;
        border-radius: 50%;
        background: #eaeaea;
        display: none;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
        margin-right: 15px;
    }

    .msg_board .user_header h3 {
        font-size: 16px;
        line-height: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .msg_board .user_header > ul {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .msg_board .user_header > ul > li {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-left: 15px; */
        cursor: pointer;
    }

    .msg_board .user_header ul li img {
        width: 15px;
        height: auto;
    }

    .msg_board :deep(.chat) {
        border: 0;
        flex: 1 1 auto;
        height: calc(100vh - 350px);
    }
    .smsCenter_wpr .contact_area{
        display: flex;
        flex-shrink: 0;
        width: 500px;
        margin-right: 20px;
    }
    .smsCenter_wpr .user_list {
        background: #fff;
        border-radius: 10px;
        padding: 15px 12px;
        height: 100%;
        flex: 0 0 325px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .smsCenter_wpr .user_list::-webkit-scrollbar {
        width: 4px;
    }
    .smsCenter_wpr .user_list:hover::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 2px;
    }

    .smsCenter_wpr .user_list .search {
        padding: 20px 5px;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 1;
    }
    .smsCenter_wpr .user_list .search .header-search-main{
        border: 0;
        background: #f5f5f5;
    }
    .smsCenter_wpr .user_list .search .header-search-main .form-field input:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
    }

    .smsCenter_wpr .message_list .user_box {
        position: relative;
    }

    .smsCenter_wpr .message_list .user_box .flag {
        width: 15px;
        height: 15px;
        position: absolute;
        left: -12px;
        top: -14px;
        cursor: pointer;
    }

    .smsCenter_wpr .message_list .user_box .flag path {
        fill: none;
        stroke: #999;
        stroke-width: 1px;
    }
    /* .smsCenter_wpr .message_list li.active .user_box .flag path {
        stroke: #666;
    } */

    .smsCenter_wpr .message_list .user_box .flag input[type=checkbox]:checked~svg path {
        fill: #F2A31D;
        stroke-width: 0;
    }

    .smsCenter_wpr .message_list .user_box .img_wpr span {
        width: 13px;
        height: 13px;
        border: 2px solid #fff;
        background: #ddd;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .smsCenter_wpr .message_list .user_box .img_wpr span.active {
        background: #30C13E;
    }

    .smsCenter_wpr .message_list .user_info {
        margin-left: 10px;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .smsCenter_wpr .msg_board .user_header .read{
        margin-left: 0;
        font-size: 15px;
        /* display: none; */
    }
    .smsCenter_wpr .msg_board .user_header .read i{
        color: #2f7eed;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr{
        width: 130px;
        left: auto;
        right: 0;
        text-align: left;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr li,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr li{
        text-align: left;
        font-size: 12px;
        line-height: 16px;
    }
    .smsCenter_wpr .message_list .user_info .msg_cont .read .dropdown_wpr::before,
    .smsCenter_wpr .msg_board .user_header .read .dropdown_wpr::before{
        display: none;
    }

    .smsCenter_wpr .message_list li:hover .user_info .msg_cont .read {
        opacity: 1;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read img,
    .smsCenter_wpr .msg_board .user_header .read img {
        width: 17px;
        height: auto;
        border-radius: 0;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read img.unread {
        display: none;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read.unread img.readed {
        display: none;
    }

    .smsCenter_wpr .message_list .user_info .msg_cont .read.unread img.unread {
        display: block;
    }

    .sms_action {
        padding: 10px;
        margin-right: 15px;
        flex: 0 0 160px;
        background: #fff;
        /* border: 1px solid #eee;
        border-right: 0; */
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;
    }
    .sms_action.active{
        flex: 0 0 120px;
    }

    .sms_action ul li {
        margin: 10px 0;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        border-radius: 10px;
        cursor: pointer;
        background: transparent;
        transition: all 0.3s ease-in-out;
        position: relative;
    }

    .sms_action ul li img {
        max-width: 18px;
        height: auto;
        margin-right: 8px;
        transition: all 0.3s ease-in-out;
    }
    /* .sms_action.active ul li img{
        margin-right: 15px;
    } */

    .sms_action ul li span {
        /* position: absolute;
        top: -2px;
        right: 0; */
        background: #2f7eed;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;
    }
    .sms_action.active ul li span{
        position: relative;
        top: auto;
    }

    .sms_action ul li.active,
    .sms_action ul li:hover {
        background: #e1eeff;
    }
    .sms_action ul.other_actions{
        margin-top: auto;
    }
    .sms_action ul.other_actions li{
        margin: 5px 0;
    }

    .modal.schedule .modal_container {
        height: 640px;
    }

    .schedule .modal_header {
        padding: 20px 30px 0;
    }

    .schedule .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .actions>ul li.delete {
        flex: 0 0 50px;
        cursor: pointer;
    }

    .actions>ul li.delete i {
        color: #f04438;
        margin: 0;
    }

    .actions>ul li .dropdown_wpr {
        max-height: 300px;
        overflow-y: scroll;
    }

    .actions>ul li .dropdown_wpr::-webkit-scrollbar {
        width: 4px;
    }

    .actions>ul li .dropdown_wpr:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }

    table.standard th {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 12px;
    }

    table.standard td {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 12px;
    }

    table.standard td .user_wpr h4 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        letter-spacing: 0;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .smsCenter_wpr .message_list .user_box .flag .tag.active path {
        fill: #2f7eed;
        stroke: #2f7eed;
    }

    .msg_board .user_header .mr-3.user_img {
        max-width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .infinite-wrapper {
        height: 100vh;
        overflow-y: overlay;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .infinite-wrapper.message_list > li{
        padding: 20px 10px 15px 20px;
    }
    .message_list{
        padding-bottom: 30px;
    }
    .message_list > li{
        margin-bottom: 10px;
        border: 1px solid #f3f3f3;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
    }
    .message_list > li.active, .message_list > li:hover {
        background: #e1eeff;
        box-shadow: none;
        border: 1px solid transparent;
    }
    .message_list .user_box .img_wpr img{
        width: 40px;
        height: 40px;
    }
    .message_list .user_info h4{
        font-size: 13px;
        line-height: 18px;
        position: relative;
    }
    .message_list .user_info h4 span{
        position: absolute;
        top: -15px;
        right: 0;
        font-size: 11px;
    }
    .message_list .user_info p{
        font-size: 11px;
        line-height: 15px;
        word-break: break-all;
        color: #5a5a5a;
    }
    .message_list .user_info p:empty{
        min-height: 15px;
    }
    .infinite-loading{
        font-size: 15px;
        line-height: 20px;
        color: #777;
    }
    .empty_text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .empty_text img{
        display: block;
        max-width: 70px;
        height: auto;
        margin: 0 auto 15px auto;
    }
    .empty_text h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #777;
    }
    .chatbox-header li {
        position: relative;
    }
    .toggle_bar{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #e9e9e9;
        font-size: 10px;
        color: #5a5a5a;
        position: absolute;
        right: 0;
        top: 25px;
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-in-out;
        z-index: 12;
    }
    .contact_area.show .toggle_bar {
        transform: rotate(-180deg);
        right: -11px;
    }
    .contact_area .sms_action .toggle_bar{
        position: fixed;
        top: 30px;
        left: -22px;
        transition: all .3s ease-in-out;
    }
    .contact_area .sms_action.show .toggle_bar{
        transform: rotate(-180deg);
        left: 150px;
    }
    .smsCenter_wpr .user_list .close_btn {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        margin-bottom: 10px;
        cursor: pointer;
        display: none;
        margin-left: auto;
    }
    .empty_chat {
        background: #fff;
        border-radius: 10px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .empty_chat h2{
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }
    .empty_chat img{
        display: block;
        max-width: 120px;
        height: auto;
        margin: 0 auto 20px auto;
    }
    @media(max-width: 1199px){
        .sms_action{
            padding: 8px;
            flex: 0 0 140px;
            margin-right: 10px;
        }
        .sms_action ul li{
            padding: 8px 12px;
        }
        .smsCenter_wpr .user_list{
            flex: 0 0 300px;
        }
        .smsCenter_wpr .contact_area{
            width: 450px;
        }
    }
    @media(max-width: 991px){
        .smsCenter_wpr{
            height: calc(100% - 60px);
        }
        .msg_board :deep(.chat) {
            height: calc(100vh - 310px);
        }
        .smsCenter_wpr .sms_action, .smsCenter_wpr .user_list{
            margin-right: 0;
            border-radius: 0;
            border-right: 1px solid #f3f3f3;
        }
        .smsCenter_wpr .contact_area{
            position: fixed;
            top: 0;
            bottom: 0;
            width: 440px;
            left: -440px;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .contact_area .toggle_bar{
            display: flex;
        }
        .smsCenter_wpr .contact_area.show{
            box-shadow: 0 1px 20px rgba(0,0,0,0.07);
            left: 0;
        }
        .msg_board .header-search-main{
            display: block;
            position: relative;
            right: auto;
            left: auto;
            top: auto;
        }
        .msg_board .header-search-main .form-field input{
            height: 45px;
        }
    }
    @media(max-width: 499px){
        .respective_content{
            min-height: auto;
        }
        .msg_board .user_header h3{
            font-size: 16px;
            line-height: 23px;
        }
        .smsCenter_wpr{
            height: calc(100vh - 110px);
            margin-top: 5px;
        }
        .smsCenter_wpr .sms_action{
            position: fixed;
            top: 0;
            bottom: 0;
            left: -160px;
            width: 160px;
            padding: 15px;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .smsCenter_wpr .sms_action.show{
            left: 0;
            box-shadow: 0 1px 20px rgba(0,0,0,0.07);
        }
        .smsCenter_wpr .user_list{
            border-right: 0;
            flex: 0 0 100%;
            padding: 15px;
        }
        .infinite-wrapper.message_list > li{
            background: transparent;
            border: 0;
            border-radius: 0;
            border-bottom: 1px solid #f3f3f3;
            padding-bottom: 20px;
        }
        .infinite-wrapper.message_list > li:last-of-type{
            border: 0;
        }
        .smsCenter_wpr .contact_area, .smsCenter_wpr .contact_area.show{
            position: static;
            width: 100%;
            box-shadow: none;
            flex-direction: column;
        }
        .contact_area > .toggle_bar{
            display: none;
        }
        .smsCenter_wpr .msg_board{
            position: fixed;
            top: 0;
            bottom: 0;
            right: -100%;
            width: 100%;
            z-index: 12;
            transition: all 0.3s ease-in-out;
            margin: 0;
        }
        .smsCenter_wpr .msg_board.show{
            right: 0;
        }
        .smsCenter_wpr .msg_board .chat_board{
            border-radius: 0;
            background: #fff;
        }
        .msg_board .header-search-main{
            display: none;
        }
        .msg_board :deep(.chat) {
            max-height: calc(100vh - 205px);
        }
        .msg_board .user_header{
            padding-left: 15px;
        }
        .msg_board .user_header .back{
            display: flex;
        }
        .smsCenter_wpr .message_list .user_info .msg_cont .read{
            display: none;
        }
        .smsCenter_wpr .msg_board .user_header .read{
            display: flex;
        }
        .sms_action ul.other_actions{
            margin-top: 0;
        }
        .sms_action ul.other_actions li{
            margin: 0 0 10px 0;
        }
    }
</style>
